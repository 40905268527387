<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <div class="flex items-center">
        <img src="@/assets/images/icons8-admin-settings-male-96.png" class="h-20 ml-2" />
        <div class="text-xl font-bold">مدیران کانال</div>
      </div>
      <h2 class="text-xl font-bold mt-3">
        <Icon name="Group"></Icon>
        {{ channel.name }}
      </h2>
      <div class="mt-3 text-right" dir="ltr">{{ channel.gid }}</div>
    </template>
    <template #default>
      <!-- admins -->
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3">
        <div
          v-for="user in users"
          :key="user.id"
          class="bg-blueGray-100 border border-blueGray-200 rounded-md overflow-hidden"
        >
          <div class="flex p-2 bg-blueGray-200">
            <div class="w-1/2 font-bold text-lg">{{ user.first_name }}</div>
            <div class="w-1/2 font-bold text-lg text-left">{{ user.last_name }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">id</div>
            <div class="w-1/2 font-bold text-left">{{ user.uid }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">نام کاربری</div>
            <div class="w-1/2 font-bold text-left">{{ user.username }}</div>
          </div>
          <div class="flex p-2">
            <div class="w-1/2">سطح</div>
            <div class="w-1/2 font-bold text-left">{{ user.userChannel.admin }}</div>
          </div>
          <div class="w-full mx-auto p-2">
            <Button
              :to="{
                name: 'ChannelOwnerSettingShowUser',
                params: {
                  id: $store.state.user.id,
                  channelId: channel.id,
                  userId: user.id,
                },
              }"
              class="text-sm h-11 lg:text-lg"
              color="cyan"
            >
              بیشتر
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Icon from "@/components/icon/Icon.vue";

// import MnrSelect from "@/components/mnr/MnrSelect.vue";
// import MnrNumber from "@/components/mnr/MnrNumber.vue";
// import MnrCheckSlider from "@/components/mnr/MnrCheckSlider.vue";
// import MnrText from "@/components/mnr/MnrText.vue";
// import MnrTextarea from "@/components/mnr/MnrTextarea.vue";

import Button from "@/components/Button.vue";
// import SettingInfoCard from "@/components/SettingInfoCard.vue";
// import SettingProCard from "@/components/SettingProCard.vue";
// import MnrSelect from "@/components/mnr/MnrSelect.vue";

export default {
  components: {
    BodySimple,
    Icon,
    Button,
    // MnrSelect,
    // SettingInfoCard,
    // SettingProCard,
    // MnrNumber,
    // MnrCheckSlider,
    // MnrText,
    // MnrTextarea,
    // MnrSelect,
  },
  data() {
    return {
      errors: [],
      firstLoading: true,
      channel: {},
      users: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      let $this = this;
      // $this.firstLoading = true;
      $this.$axios
        .get(`/api/owner-channels/${$this.$route.params.channelId}/admins`, {
          params: {
            id: $this.$store.state.user.id,
            // search: $this.searchTools,
            // date: $this.date,
          },
        })
        .then(function (response) {
          // console.log(response);
          $this.channel = response.data.channel;
          $this.users = response.data.users;
          // $this.date = response.data.date;
        })
        .catch(function (error) {
          // console.log(error);
        })
        .then(function () {
          $this.firstLoading = false;
          $this.sending = false;
        });
    },
  },
};
</script>

<style></style>
